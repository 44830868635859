<template>
    <a :href="path" :class="className" @click.prevent="handleClick">
      <slot />
    </a>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";

const props = defineProps({
    activeClass: {
        type: String,
        default: ''
    },
    inactiveClass: {
        type: String,
        default: ''
    },
    href: {
        type: String,
        required: true
    }
});

const route = useRoute();

const path = computed(() => {
    const to = props.href || route.path;
    if (_.isNil(to) || to === undefined || to === 'undefined' || (typeof to === 'string' && _.isEmpty(to.trim()))) {
        sentryCaptureEvent({
            message: 'nav-link.undefined',
            level: 'error',
            extra: { props, to }
        });

        return '/';
    }

    return to;
});

const className = computed(() => {
    if (props.href === translatePath(ROUTES.CODE.HOME)) {
        return route.path === props.href
          ? props.activeClass
          : props.inactiveClass;
    }

    return _.startsWith(route.path, props.href)
      ? props.activeClass
      : props.inactiveClass;
});

const handleClick = async () => {
    await navigatorTo(path.value);
}

</script>
